import React from 'react'
import Layout from '../../components/layout'

import Header from '../../components/Header'
import Main from '../../components/Main'
import Footer from '../../components/Footer'
import { Message } from '../../components/Message'

class SuggestionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isArticleVisible: true,
      timeout: true,
      articleTimeout: true,
      article: 'about',
      loading: 'is-loading',
      isLightOn: true
    }
    this.handleOpenArticle = this.handleOpenArticle.bind(this)
    this.handleCloseArticle = this.handleCloseArticle.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onLight = this.onLight.bind(this);
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
        this.setState({loading: ''});
    }, 100);
    document.addEventListener('mousedown', this.handleClickOutside);
    if (document.cookie.indexOf("countryCode=") === -1) {
      fetch(`https://organizestaging.com/country`);
    }
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOpenArticle(article, e) {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    this.setState({
      isArticleVisible: !this.state.isArticleVisible,
      article
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        articleTimeout: !this.state.articleTimeout
      })
    }, 350)

  }

  handleCloseArticle() {

    this.setState({
      articleTimeout: !this.state.articleTimeout
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
        article: ''
      })
    }, 350)

  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle();
      }
    }
  }

  onLight() {
    this.setState({isLightOn: !this.state.isLightOn});
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div className={`body light ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>
          <div id="wrapper">
            <Header onOpenArticle={this.handleOpenArticle} timeout={this.state.timeout} onLight={this.onLight}  location={this.props.location}/>
            <Main
              isArticleVisible={this.state.isArticleVisible}
              timeout={this.state.timeout}
              articleTimeout={this.state.articleTimeout}
              article={this.state.article}
              onCloseArticle={this.handleCloseArticle}
              setWrapperRef={this.setWrapperRef}
            />
            <div style={{marginTop: 20, textTransform: 'uppercase',fontWeight: 'bold'}}><Message bundle="common" messageKey="startYourTrial"/></div>
            <div style={{marginTop: 20}}>
              <a className="applebadge" href="https://apps.apple.com/es/app/organizestaging/id1492644361?mt=8"></a>
              <a className="googlebadge" href='https://play.google.com/store/apps/details?id=com.organizestaging.organizestaging&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/es_badge_web_generic.png' width="150px"/></a>
            </div>
            <Footer timeout={this.state.timeout} />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    )
  }
}

export default SuggestionsPage
